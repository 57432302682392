import { useState, useEffect, useContext } from "react";
import AppContext from "../AppContext";
import remToPx from "../utils/remToPx";
import { Github, Gmail, LinkedIn } from "../utils/renderSvg";

const Header = () => {
  const { sections, sectionRefs, screenWidth } = useContext(AppContext);
  const [activeLink, setActiveLink] = useState(sections[0].name);

  useEffect(() => {
    // minLaptop is true for minimum laptop screensize
    const minLaptop = screenWidth >= 1024;
    const handleHashChange = () => {
      try {
        const fragment = window.location.hash.substring(1);
        if (fragment in sectionRefs) {
          window.scrollTo({
            behavior: "smooth",
            top:
              sectionRefs[fragment]?.offsetTop -
              (minLaptop ? remToPx(6) : remToPx(4)),
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    const handleScrollChange = () => {
      try {
        const offset = remToPx(9);
        const scrollPosition = window.scrollY;
        for (const key in sectionRefs) {
          const section = sectionRefs[key];
          const top = section?.offsetTop - offset; // Adjust this value to set the activation threshold
          const bottom = top + section?.offsetHeight + offset;
          if (scrollPosition >= top && scrollPosition < bottom) {
            setActiveLink(section?.id);
            break;
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    handleHashChange();
    window.addEventListener("hashchange", handleHashChange);
    if (minLaptop) window.addEventListener("scroll", handleScrollChange);
    else window.removeEventListener("scroll", handleScrollChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
      window.removeEventListener("scroll", handleScrollChange);
    };
  }, [screenWidth, sectionRefs]);

  const handleLinkClick = (e, section) => {
    e.preventDefault();
    window.history.pushState(null, null, `#${section.name}`);
    window.dispatchEvent(new Event("hashchange"));
  };

  return (
    <header className="lg:sticky top-0 lg:w-1/2 lg:max-h-screen lg:py-24 lg:flex lg:flex-col lg:justify-between">
      <div>
        <h1 className="text-4xl sm:text-5xl text-slate-200 font-bold tracking-tight mb-3">
          Daniyal Khalid
        </h1>
        <h2 className="text-lg sm:text-xl text-slate-200 font-medium mb-4">
          Full Stack Web Developer
        </h2>
        <p className="mb-8 max-w-[350px]">
          MERN Full Stack Web Developer | 5 Years of Professional Experience
          | Building Dynamic and Scalable Solutions
        </p>
        <div className="hidden lg:flex lg:flex-col w-max">
          {sections.map((section, index) => {
            const { name } = section;
            return (
              <a
                key={index}
                href={"#" + name}
                className={`group${
                  activeLink === name ? " active" : ""
                } flex items-center py-3 cursor-pointer`}
                onClick={(e) => handleLinkClick(e, section)}
              >
                <span className="inline-block h-px mr-4 w-8 group-[.active]:w-16 group-hover:w-16 group-focus-visible:w-16 bg-slate-600 group-[.active]:bg-slate-200 group-hover:bg-slate-200 group-focus-visible:bg-slate-200 transition-all"></span>
                <span className="text-xs font-bold uppercase tracking-widest text-slate-500 group-[.active]:text-slate-200 group-hover:text-slate-200 group-focus-visible:text-slate-200">
                  {name}
                </span>
              </a>
            );
          })}
        </div>
      </div>
      <div className="mt-8 flex gap-x-5">
        <a
          href="https://github.com/dkcent4"
          target="_blank"
          rel="noreferrer noopener"
          className="hover:text-slate-200"
        >
          <Github />
        </a>
        <a
          href="https://www.linkedin.com/in/daniyal-khalid-788515159/"
          target="_blank"
          rel="noreferrer noopener"
          className="hover:text-slate-200"
        >
          <LinkedIn />
        </a>
        <a
          href="mailto:daniyal.khalid.1196@gmail.com"
          target="_blank"
          rel="noreferrer noopener"
          className="hover:text-slate-200"
        >
          <Gmail />
        </a>
      </div>
    </header>
  );
};

export default Header;
