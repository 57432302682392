import { useContext } from "react";
import AppContext from "../AppContext";

const Section = ({ name, component: Component }) => {
  const { sectionRefs } = useContext(AppContext);
  return (
    <section
      id={name}
      ref={(el) => (sectionRefs[name] = el)}
      className={`group/section${
        name !== "projects" ? " mb-16 md:mb-24 lg:mb-36" : ""
      }`}
    >
      <div className="sticky top-0 z-20 -mx-6 mb-4 bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:hidden">
        <h2 className="text-sm font-bold uppercase tracking-widest text-slate-200">
          {name}
        </h2>
      </div>
      <Component />
    </section>
  );
};

export default Section;
