import { ArrowLink } from "../utils/renderSvg";
import ConditionalLink from "./conditionalLink";
import LazyImage from "./lazyImage";
import SectionRow from "./sectionRow";

const PROJECTS = [
  {
    thumbnail: "almo.webp",
    title: "Almo",
    link: "https://almo-eta.vercel.app/",
    summary:
      "This project is a cutting-edge meditation and breathing web app built with Next.js at Etra Group LTD and currently under development. As the sole full-stack developer, I’ve implemented dynamic animations for guided breathing techniques, secure authentication and authorization with NextAuth.js, and a journaling feature for capturing personal reflections. This initiative underscores my ability to deliver innovative, scalable solutions that engage users and promote wellbeing.",
    skills: [
      "Next.js (v15 + App Router)",
      "Typescript",
      "Tailwind CSS",
      "NextAuth.js",
      "Shadcn UI",
    ],
  },
  {
    thumbnail: "cloud-solution.webp",
    title: "Cloud Solution",
    link: "https://cloud-solution-dkcent4s-projects.vercel.app/",
    summary:
      "I developed a cloud solution provider clone that allowed me to deepen my expertise in Next.js, TypeScript, and Tailwind CSS. During this project, I experimented with advanced CSS techniques such as parallax scrolling and scroll spy navigation and implemented engaging, smooth animations. This hands-on experience not only sharpened my technical skills but also increased my confidence in creating responsive, high-quality web applications using modern frameworks.",
    skills: ["Next.js (v15 + App Router)", "Typescript", "Tailwind CSS"],
  },
  {
    thumbnail: "superviral.webp",
    title: "Superviral",
    link: "https://superviral.io/",
    summary:
      "One of the projects I developed and maintained at Etra Group LTD is a website that caters to social media growth services. It was built using pure technologies such as HTML, CSS, and JavaScript, without any frameworks. This approach enabled a fast, responsive, and streamlined user experience, fully optimized for performance and resulting in significant revenue for the company.",
    skills: ["HTML", "CSS", "JavaScript"],
  },
  {
    thumbnail: "cricwick.webp",
    title: "Cricwick",
    link: "https://cricwick.net/",
    summary:
      "I contributed to a cricket-focused website tailored for Pakistani enthusiasts, developed with React and integrated APIs from various sources. My responsibilities included bug fixes, API integration, and providing support for new features, ensuring a seamless user experience for cricket lovers.",
    skills: ["React", "HTML", "CSS", "Bootstrap"],
  },
  {
    thumbnail: "musiclab.webp",
    title: "Music Lab",
    link: "https://musiclab.arhamsoft.org/",
    summary:
      "Collaborated as a MERN stack developer for this web app, which facilitated the buying and selling of music as NFTs. Within a dynamic team environment, I showcased strong teamwork qualities, contributing to the development, testing, and deployment phases. My role encompassed refining user interfaces, implementing backend functionalities, and ensuring seamless integration of blockchain technology. This experience honed my ability to collaborate effectively while driving innovation in a cutting-edge domain.",
    skills: ["MongoDB", "Express", "React", "Node.js", "Bootstrap"],
  },
  {
    thumbnail: "hydra-landing-page.webp",
    title: "Hydra Landing Page",
    link: "https://hydra-landing-page.vercel.app/",
    summary:
      "I transformed a Figma community design into a sleek single-page website using create-react-app. Built entirely from scratch, it features custom sliders, navigation menus, and interactive elements. Fully responsive and cross-browser compatible, it's hosted on Vercel. Minimized reliance on third-party packages for personal practice and passion",
    skills: ["React", "HTML", "CSS"],
  },
];

const Projects = () => {
  return (
    <>
      {PROJECTS.map((proj, index) => (
        <SectionRow
          key={index}
          link={proj.link}
          className={`relative ${
            index < PROJECTS.length - 1 ? "mb-12 " : ""
          }flex flex-col gap-4 sm:flex-row sm:gap-8 md:gap-4 group lg:hover:!opacity-100 lg:group-hover/section:opacity-50 transition-all lg:cursor-pointer`}
        >
          <div className="flex-1 sm:order-1 z-10">
            <ConditionalLink
              link={proj.link}
              className={
                "text-slate-200 font-medium leading-snug inline-block mb-2 group/link hover:text-teal-300 lg:group-hover:text-teal-300 focus-visible:text-teal-300 lg:group-focus-visible:text-teal-300"
              }
            >
              {proj.title}
              <ArrowLink />
            </ConditionalLink>
            <p className="text-sm leading-normal mb-2">{proj.summary}</p>
            <ul className="flex flex-wrap">
              {proj.skills.map((skill, index) => (
                <li key={index} className="mr-1.5 mt-2">
                  <div className="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300">
                    {skill}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <LazyImage imageName={proj.thumbnail} alt="project thumbnail" />
        </SectionRow>
      ))}
    </>
  );
};

export default Projects;
